import React, { FC } from 'react';

import { Link, SEO } from '../components';

import { withLayout } from '../components/Layout';
import { Container } from 'emotion-flex';
import presets from '../styles/presets';
import { SYSTEM_FONTS } from '../styles/typography';
import { useIntl } from 'react-intl';
import { HB_PDF_URL, IBD_PDF_URL, IBS_PDF_URL } from '../utils/constants';

interface Props {
  pathContext: {
    locale: 'en' | 'de';
  };
}

const ZweckbestimmungPage: FC<Props> = ({ pathContext: { locale } }) => {
  const intl = useIntl();

  const renderSection = (title: string, pdfURL: string, linkText: string) => (
    <Container>
      <h3 css={{ marginTop: '3%' }}>{title}</h3>
      <Link to={pdfURL} target="_blank" rel="noopener noreferrer">
        {linkText}
      </Link>
    </Container>
  );

  return (
    <>
      <SEO
        lang={locale}
        title={intl.formatMessage({
          id: 'zweckbestimmung.seo',
          defaultMessage: 'Zweckbestimmung',
        })}
      />
      <Container css={{ padding: '2%' }}>
        <h1
          css={{
            fontFamily: ['Blacker Pro Display'].concat(SYSTEM_FONTS).join(', '),
            fontSize: 32,
            color: '#160B2C',
            marginBottom: 12,
            [presets.md]: { fontSize: 48 },
          }}
        >
          {intl.formatMessage({
            id: 'zweckbestimmung.title',
            defaultMessage:
              ' Zweckbestimmung für Cara Care für Reizdarm, CED und Sodbrennen',
          })}
        </h1>
        {renderSection(
          intl.formatMessage({
            id: 'zweckbestimmung.ibs.title',
            defaultMessage: 'Zweckbestimmung für Cara Care für Reizdarm:',
          }),
          IBS_PDF_URL,
          intl.formatMessage({
            id: 'zweckbestimmung.ibs.text',
            defaultMessage:
              'Zweckbestimmung für Cara Care für Reizdarm als PDF herunterladen',
          })
        )}
        {renderSection(
          intl.formatMessage({
            id: 'zweckbestimmung.ibd.title',
            defaultMessage: 'Zweckbestimmung für Cara Care für CED:',
          }),
          IBD_PDF_URL,
          intl.formatMessage({
            id: 'zweckbestimmung.ibd.text',
            defaultMessage:
              'Zweckbestimmung für Cara Care für CED als PDF herunterladen',
          })
        )}
        {renderSection(
          intl.formatMessage({
            id: 'zweckbestimmung.hb.title',
            defaultMessage: 'Zweckbestimmung für Cara Care für Sodbrennen:',
          }),
          HB_PDF_URL,
          intl.formatMessage({
            id: 'zweckbestimmung.hb.text',
            defaultMessage:
              'Zweckbestimmung für Cara Care für Sodbrennen als PDF herunterladen',
          })
        )}
      </Container>
    </>
  );
};

export default withLayout(ZweckbestimmungPage, { isNewLayout: true });
